// extracted by mini-css-extract-plugin
export var action = "form-module--action--3d8c1";
export var answer = "form-module--answer--d0996";
export var back = "form-module--back--135bf";
export var background = "form-module--background--0cb28";
export var block = "form-module--block--d08ff";
export var box = "form-module--box--5d5cf";
export var checkbox = "form-module--checkbox--e9c63";
export var checked = "form-module--checked--57606";
export var close = "form-module--close--df03a";
export var complete = "form-module--complete--688b6";
export var contact = "form-module--contact--1d01c";
export var container = "form-module--container--4f69e";
export var content = "form-module--content--c36cf";
export var error = "form-module--error--dcc25";
export var form = "form-module--form--ef385";
export var head = "form-module--head--2baac";
export var icon = "form-module--icon--69ee5";
export var input = "form-module--input--96e68";
export var label = "form-module--label--5cc68";
export var logo = "form-module--logo--0c96a";
export var resultfooter = "form-module--resultfooter--5043e";
export var submit = "form-module--submit--f5d88";