import React, { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import cx from "classnames";
import axios from "axios";
import { InputMask } from "primereact/inputmask";
import { motion } from "framer-motion";
import { IoCheckmarkSharp } from "react-icons/io5";

import Logo from "./svg/logo";

import * as styles from "./form.module.scss";

// Отправка сообщения в телеграм
const TOKEN = `${process.env.GATSBY_TELEGRAM_TOKEN}`;
const CHAT_ID = `${process.env.GATSBY_TELEGRAM_CHAT_ID}`;
const SEND_MESSAGE_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

function Form({ close, callback }) {
  const [isLoading, setIsLoading] = useState(false);
  const [contact, setContact] = useState({
    form: callback.name,
    name: "",
    email: "",
    phone: "",
    agreement: false,
    complete: false,
    status: "success",
  });

  const isNumber = contact.phone !== "" && !contact.phone.includes("_");

  const activeSubmite =
    contact.name !== "" &&
    (contact.email !== "" || isNumber) &&
    contact.agreement;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const messageText =
      "Заявка с сайта | Дезинфекция в Казани" +
      "\n" +
      `<b>Форма:</b> ${contact.form}` +
      "\n" +
      `<b>Почта/Мессенджер:</b> ${contact.email}` +
      "\n" +
      `<b>Телефон:</b> ${contact.phone}` +
      "\n" +
      `<b>Имя:</b> ${contact.name}`;

    const messageData = new FormData();
    messageData.append("chat_id", CHAT_ID);
    messageData.append("parse_mode", "HTML");
    messageData.append("text", messageText);

    // Ваш код для отправки данных формы на сервер
    axios
      .post(SEND_MESSAGE_API, messageData)
      .then(function () {
        setContact((prev) => ({ ...prev, complete: true }));
      })
      .catch((err) => console.log(err));

    // Очистка полей формы после отправки
  };

  return (
    <div className={styles.container}>
      <motion.div
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.4,
            ease: [0.24, 0.68, 0.79, 0.96],
          },
        }}
        initial={{ opacity: 0, y: 20 }}
        className={styles.block}
      >
        <div className={styles.content}>
          {contact.complete ? (
            <div className={styles.answer}>
              {contact.status === "success" && (
                <div className={styles.complete}>
                  <Logo className={styles.logo} />
                  <h4>{contact.name}, спасибо за заявку!</h4>
                  <div className={styles.action}>
                    <p>Наш специалист свяжется с Вами в ближайшее время.</p>
                    <button type="button" className="link" onClick={close}>
                      <p>Вернуться к сайту</p>
                    </button>
                  </div>
                </div>
              )}
              {contact.status === "error" && (
                <div className={styles.error}>
                  <Logo className={styles.logo} />
                  <h4>Что-то пошло не так...</h4>
                  <p>
                    К сожалению, мы не смогли отправить Вашу форму. Но Вы можете
                    связаться с нами тут:
                  </p>
                  <div className={styles.resultfooter}>
                    <a href="call:+79063291668" className="link">
                      <p>+7 (906) 329 16 68</p>
                    </a>
                    <a href="mailto:dezinsekciya.pro@mail.ru" className="link">
                      <p>dezinsekciya.pro@mail.ru</p>
                    </a>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.contact}>
              <div className={styles.head}>
                <h3>{callback.title}</h3>
                <p>{callback.description}</p>
              </div>

              <form
                className={styles.form}
                onSubmit={handleSubmit}
                id={`body_${callback.id}`}
              >
                <div className={styles.input}>
                  <input
                    required={true}
                    autoComplete="off"
                    value={contact.name}
                    name="user_name"
                    onChange={(e) =>
                      setContact((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                  <span className={styles.label}>Имя</span>
                </div>
                <div className={styles.input}>
                  <input
                    required={false}
                    autoComplete="off"
                    value={contact.email}
                    name="user_email"
                    onChange={(e) =>
                      setContact((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                  <span className={styles.label}>Почта/Мессенджер</span>
                </div>
                <div className={styles.input}>
                  <InputMask
                    required={false}
                    autoComplete="off"
                    value={contact.phone}
                    name="user_phone"
                    onChange={(e) =>
                      setContact((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    }
                    mask="+7 (999) 999-99-99"
                  />
                  <span className={styles.label}>Телефон</span>
                </div>

                <button
                  className={cx(
                    styles.checkbox,
                    contact.agreement && styles.checked
                  )}
                  type="button"
                  onClick={() =>
                    setContact((prev) => ({
                      ...prev,
                      agreement: !prev.agreement,
                    }))
                  }
                >
                  <div className={styles.box}>
                    <IoCheckmarkSharp className={styles.icon} />
                  </div>
                  <p className={styles.label}>
                    Я согласен(-на) на обработку моих персональных данных
                  </p>
                </button>
                <div className={styles.submit}>
                  <button
                    className={cx(
                      "button orange full-width",
                      !activeSubmite && "nonactive",
                      isLoading && "loading"
                    )}
                    type="submit"
                    id={callback.id}
                  >
                    {isLoading ? <p>Отправляем...</p> : <p>Отправить</p>}
                  </button>
                </div>
              </form>
            </div>
          )}
          <button className={styles.close} onClick={close}>
            <IoCloseOutline className={styles.icon} />
          </button>
        </div>
      </motion.div>
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        className={styles.background}
        onClick={close}
      />
    </div>
  );
}

export default Form;
