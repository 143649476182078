import React from "react";
import { Link } from "gatsby";

import * as styles from "./footer.module.scss";

function Footer({ openform }) {
  return (
    <footer className="margin-top">
      <div className="container">
        <div className={styles.contact}>
          <div className={styles.block}>
            <div className={styles.item}>
              <p>Телефон</p>
              <a
                href="tel:+79063291668"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                +7 (906) 329 16 68
              </a>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.item}>
              <p>Почта</p>
              <a
                href="mailto:dezinsekciya.pro@mail.ru"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                dezinsekciya.pro@mail.ru
              </a>
            </div>
            <div className={styles.item}>
              <p>Социальные сети</p>
              <p className={styles.social}>
                <a
                  href="https://t.me/+79063291668"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  Telegram
                </a>
                {" / "}
                <a
                  href="https://wa.me/+79063291668"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  WhatsApp
                </a>
              </p>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.callback}>
              <div className={styles.head}>
                <h3>Связаться с нами</h3>
                <p>
                  Вы можете оставить свой вопрос и наш менеджер обязательно
                  свяжется с Вами
                </p>
              </div>

              <button className="button full-width white" onClick={openform}>
                Оставить заявку
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.underground}>
        <Link to="/policy" className={styles.link}>
          Политика конфиденциальности
        </Link>
        <p>
          Сайт создан командой{" "}
          <a
            href="https://heycoddes.ru"
            target="_blank"
            rel="noreferrer"
            className={styles.heycoddes}
          >
            Hey, Coddes
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
