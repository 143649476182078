// extracted by mini-css-extract-plugin
export var callback = "header-module--callback--2f5ee";
export var container = "header-module--container--8b59b";
export var desktop = "header-module--desktop--d3b2b";
export var details = "header-module--details--23394";
export var full = "header-module--full--2858b";
export var hint = "header-module--hint--1c64f";
export var icon = "header-module--icon--f14cf";
export var links = "header-module--links--71430";
export var logo = "header-module--logo--cbdef";
export var mobile = "header-module--mobile--28f45";
export var navigation = "header-module--navigation--2fde5";
export var services = "header-module--services--cfcb4";
export var wrap = "header-module--wrap--76a98";