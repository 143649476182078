import React, { useState, useEffect } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import Footer from "./footer";
import Form from "./form";
import Header from "./header";

const queryClient = new QueryClient();

function Layout({ children }) {
  const [callback, setCallback] = useState({
    title: "",
    description: "",
    name: "",
    open: false,
  });

  useEffect(() => {
    if (callback.open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [callback.open]);

  return (
    <QueryClientProvider client={queryClient}>
      <Header
        openform={() =>
          setCallback({
            title: "Консультация",
            description: "Заполните форму и мы свяжемся с Вами",
            name: `Консультация на странице ${document.title}`,
            open: true,
          })
        }
      />
      <main>{children}</main>
      <Footer
        openform={() =>
          setCallback({
            title: "Задать вопрос",
            description: "Заполните форму и мы свяжемся с Вами",
            name: `Задать вопрос на странице ${document.title}`,
            open: true,
          })
        }
      />
      {callback.open && (
        <Form
          callback={callback}
          setCallback={setCallback}
          close={() =>
            setCallback({
              title: "",
              description: "",
              name: "",
              open: false,
            })
          }
        />
      )}
    </QueryClientProvider>
  );
}

export default Layout;
