import React, { useState } from "react";
import { Link } from "gatsby";
import cx from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import { IoCallOutline, IoClose } from "react-icons/io5";
import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

import Logo from "./svg/logo";

import * as styles from "./header.module.scss";

const mobileCallback = [
  {
    link: "tel:+79063291668",
    icon: <IoCallOutline />,
  },
  {
    link: "https://wa.me/+79063291668",
    icon: <FaWhatsapp />,
  },
  {
    link: "https://t.me/+79063291668",
    icon: <FaTelegramPlane />,
  },
];

function Header({ openform }) {
  const [openLinks, setOpenLinks] = useState(false);
  const [openCallback, setOpenCallback] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        nodes {
          title
          href
        }
      }
    }
  `);

  const links = data.allServicesJson.nodes;

  const MotionLink = motion(Link);

  return (
    <div className={styles.wrap}>
      <header className={cx(styles.container, styles.desktop)}>
        <nav className={styles.navigation}>
          <Link to="/" className={styles.logo}>
            <Logo className={styles.icon} />
          </Link>
          <Link to="/blog" className="link">
            <p>Все о дезинфекции</p>
          </Link>

          <div className={styles.services}>
            <button className={styles.hint}>
              <p>Услуги</p>
            </button>
            <div className={styles.links}>
              {links.map((item, index) => {
                return (
                  <Link key={`link_${index}`} to={item.href} className="link">
                    <p>{item.title}</p>
                  </Link>
                );
              })}
            </div>
          </div>
        </nav>
        <div className={styles.callback}>
          <a href="tel:+79063291668" className="link">
            +7 (906) 329 16 68
          </a>
          <a href="https://wa.me/+79063291668" className="button-icon white">
            <FaWhatsapp />
          </a>
          <a href="https://t.me/+79063291668" className="button-icon white">
            <FaTelegramPlane />
          </a>
          <button className="button orange" onClick={openform}>
            <p>Консультация</p>
          </button>
        </div>
      </header>

      <header className={cx(styles.container, styles.mobile)}>
        <AnimatePresence initial={false} mode="popLayout">
          {openLinks && (
            <div className={styles.details}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0 } }}
                className={styles.links}
              >
                {links.map((item, index) => {
                  return (
                    <MotionLink
                      initial={{ opacity: 0, x: 5 + index * 2 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: { delay: 0.15 + 0.05 * index },
                      }}
                      exit={{ opacity: 0 }}
                      key={`linkmobile_${index}`}
                      to={item.href}
                      className="link mobile"
                      onClick={() => setOpenLinks(false)}
                    >
                      <p>{item.title}</p>
                    </MotionLink>
                  );
                })}
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <nav className={styles.navigation}>
          <Link
            to="/"
            className={styles.logo}
            onClick={() => setOpenLinks(false)}
          >
            <Logo className={styles.icon} />
          </Link>
          <div className={styles.mobile}>
            <Link to="/blog" className="link">
              <p>Полезное</p>
            </Link>
            <button className="link" onClick={() => setOpenLinks(!openLinks)}>
              {openLinks ? <p>Скрыть</p> : <p>Услуги</p>}
            </button>
            <div className={styles.callback}>
              <button
                className={cx(
                  openCallback
                    ? "button-icon border-orange"
                    : "button-icon orange"
                )}
                onClick={() => setOpenCallback(!openCallback)}
              >
                {openCallback ? <IoClose /> : <IoCallOutline />}
              </button>

              <div className={styles.full}>
                {openCallback && (
                  <AnimatePresence mode="popLayout">
                    {mobileCallback.map((item, index) => {
                      return (
                        <motion.a
                          initial={{ opacity: 0, y: -4 + -3 * index }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                              duration: 0.3 + 0.1 * index,
                              delay: 0.1 + 0.05 * index,
                            },
                          }}
                          exit={{ opacity: 0 }}
                          href={item.link}
                          key={`mobilelink_${index}`}
                          className="button-icon orange"
                        >
                          {item.icon}
                        </motion.a>
                      );
                    })}
                  </AnimatePresence>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
